// module colors
:root {
  --module-color: var(--bs-secondary);
  --module-color-rgb: var(--bs-secondary-rgb);

  --dashboard: var(--bs-px-hl);
  --dashboard-rgb: var(--bs-px-hl-rgb);

  --customer: rgb(227, 149, 0);
  --customer-rgb: 227, 149, 0;

  --order: rgb(229, 104, 116);
  --order-rgb: 229, 104, 116;

  --variant: rgb(164, 83, 142);
  --variant-rgb: 164, 83, 142;

  --campaign: var(--bs-purple);
  --campaign-rgb: var(--bs-purple-rgb);

  --cycle: var(--bs-green);
  --cycle-rgb: var(--bs-green-rgb);

  --quickSight: var(--bs-info);
  --quickSight-rgb: var(--bs-info-rgb);

  --actionList: var(--bs-teal);
  --actionList-rgb: var(--bs-teal-rgb);

  --documents: rgb(255, 85, 85);
  --documents-rgb: 255, 85, 85;

  --treatment: rgb(173, 106, 184);
  --treatment-rgb: 173, 106, 184;

  --production: rgb(72, 112, 189);
  --production-rgb: 72, 112, 189;

  --product: rgb(41, 161, 113);
  --product-rgb: 41, 161, 113;

  --package: rgb(72, 169, 189);
  --package-rgb: 72, 169, 189;
}

.dashboard {
  --module-color: var(--dashboard);
  --module-color-rgb: var(--dashboard-rgb);
}
.customer {
  --module-color: var(--customer);
  --module-color-rgb: var(--customer-rgb);
}
.order {
  --module-color: var(--order);
  --module-color-rgb: var(--order-rgb);
}
.variant {
  --module-color: var(--variant);
  --module-color-rgb: var(--variant-rgb);
}
.campaign {
  --module-color: var(--campaign);
  --module-color-rgb: var(--campaign-rgb);
}
.cycle {
  --module-color: var(--cycle);
  --module-color-rgb: var(--cycle-rgb);
}
.quickSight {
  --module-color: var(--quickSight);
  --module-color-rgb: var(--quickSight-rgb);
}
.actionList {
  --module-color: var(--actionList);
  --module-color-rgb: var(--actionList-rgb);
}
.documents {
  --module-color: var(--documents);
  --module-color-rgb: var(--documents-rgb);
}
.treatment {
  --module-color: var(--treatment);
  --module-color-rgb: var(--treatment-rgb);
}
.production {
  --module-color: var(--production);
  --module-color-rgb: var(--production-rgb);
}
.product {
  --module-color: var(--product);
  --module-color-rgb: var(--product-rgb);
}
.package {
  --module-color: var(--package);
  --module-color-rgb: var(--package-rgb);
}
