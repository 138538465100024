@import "../../node_modules/bootstrap/scss/functions";

// Custom colors
$px-hl: #8fb80b;
$px-dark: #003145;
$subtle-dark: tint-color(desaturate($px-dark, 80%), 5%);

// Bootstrap colors
$primary: #006891;
$secondary: #4b626b;
$success: #00994d;
// $success: #008741;
$info: #11b4d4;
$purple: #6b598b;
$teal: #1aa9a1;
$cust-color: hsla(45, 100%, 40%, 0.526);

$body-color: #444;
$body-bg: #dee2e6;
$border-color: rgba(0, 0, 0, 0.05);
$min-contrast-ratio: 3;

@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";

// $body-font-size: 15px;
$body-color-dark: $gray-500;
$body-secondary-color-dark: #768593;
$body-emphasis-color-dark: $gray-400;
$body-tertiary-bg-dark: rgba(0, 0, 0, 0.2);
$headings-color-dark: $gray-500;
$link-color-dark: tint-color($primary, 25%);
$border-color-translucent: rgba(0, 0, 0, 0.05);
$border-color-translucent-dark: rgba(255, 255, 255, 0.05);
$border-color-dark: $border-color-translucent-dark;

$custom-colors: (
  "px-hl": $px-hl,
  "px-dark": $px-dark,
  "purple": $purple,
  "teal": $teal,
  "green": $green,
  "cust-color": $cust-color,
);

:root {
  --highlight: #8fb80b;
  --px-dark: #2c4e5c;
  --subtle-dark: #{$subtle-dark};
  --btn-dark: #25434f;
}

// Merge custom colors with Bootstrap maps
$theme-colors: map-merge($theme-colors, $custom-colors);

// Bootstrap settings

// Grid
$grid-breakpoints: (
  "xxs": 0,
  "xs": 480px,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  "xxl": 1400px,
  "xxxl": 1600px,
);

$enable-caret: false;
$enable-important-utilities: false;
$enable-negative-margins: true;

$card-border-radius: 0;
$card-inner-border-radius: 0;

.list-group-item {
  transition: background-color 0.2s;
}

$form-check-input-checked-bg-color: $success;
$input-bg: $light;

$dropdown-border-radius: 0;
$dropdown-bg: $light;
$dropdown-link-hover-bg: rgba(var(--bs-secondary-rgb), 0.1);
$dropdown-link-active-bg: rgba(var(--bs-secondary-rgb), 0.2);
$dropdown-divider-margin-y: 0;
$dropdown-padding-y: 0;
$dropdown-header-padding-y: 0.65em;
$dropdown-item-padding-y: 0.65em;
$dropdown-inner-border-radius: 0;
.dropdown-item {
  transition: background 0.2s, color 0.2s;
}

$badge-font-weight: 500;

$alert-margin-bottom: 0;
$alert-border-radius: 0;
$alert-border-width: 0;

$btn-disabled-opacity: 0.2;
$btn-padding-x-sm: 0.6rem;
.btn.btn-lg,
.btn-group-lg > .btn {
  --bs-btn-font-weight: 300;
}

$navbar-brand-font-size: 1em;
$navbar-brand-margin-end: 0;

$toast-border-radius: 0;
$toast-border-width: 0;
$toast-header-color: inherit;
$toast-header-background-color: transparent;
$toast-background-color: #f8f9fa;
$toast-spacing: 0.5rem;

$table-bg: $light;
$table-color: $body-color;
$table-state-color: $body-color;
$table-striped-color: $body-color;
$table-striped-bg: rgba(var(--bs-px-dark-rgb), 0.05);
$table-state-bg: rgba(var(--bs-px-dark-rgb), 0.1);
$table-hover-bg: rgba(var(--bs-px-dark-rgb), 0.15);
$table-border-color: var(--bs-gray-200);
.table thead th {
  background: var(--bs-gray-300);
  font-weight: 600;
}
.table-hover > tbody > tr > * {
  transition: background 0.2s, box-shadow 0.2s;
}
$table-cell-padding-y-sm: 0;
$table-cell-padding-x-sm: 0.5rem;
$table-cell-vertical-align: middle;
.table.table-sm {
  & > thead {
    height: 40px;
    vertical-align: middle;
  }
  td {
    height: 37px;
  }
}
// $offcanvas-bg: $light;

@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/breadcrumb";
// @import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";

// @import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";

@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas";
// @import "../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

// Global styles
.btn-sm {
  .badge:not(.position-absolute) {
    top: -2px;
  }
  .position-absolute {
    position: absolute;
  }
}

.btn-close {
  background-size: 0.5em;
}

$headings: "h1,.h1,h2,.h2,h3,.h3";

#{$headings},
.modal-title {
  font-weight: 300;
}

svg {
  fill: currentColor;
  overflow: visible;
  vertical-align: -10%;
}

.btn:not(.btn-link) svg {
  vertical-align: -8%;
}

.icon-as-img {
  width: 100%;
  display: block;
  height: 100%;
  padding: 25%;
  color: rgba(0, 0, 0, 0.1);
}

.icon-inline {
  width: 1em;
  height: 1em;
}

.cancel-container-margin {
  margin: 0px -13px;
}

.badge-round {
  width: 1.9em;
}

dt {
  font-weight: 600;
}

.flip-horizontal {
  transform: scaleX(-1);
}

.img-cover {
  object-fit: cover;
}
.img-contain {
  object-fit: contain;
}

.border-transparent {
  border-color: transparent;
}

.no-last-border-bottom {
  > :last-child {
    border-bottom: none;
  }
}

.btn-close {
  --bs-btn-close-focus-shadow: none;
}

h4,
.h4 {
  font-weight: 300;
}
h5,
.h5 {
  font-weight: 300;
}
h6 {
  font-weight: 600;
}

// Quicksight report
#qsContainer {
  position: relative;
  overflow-x: hidden;
  .quicksight-embedding-iframe {
    // height: 100%;
    inset: 0;
  }
  .quicksight-embedding-iframe-placeholder {
    padding-left: 45px;
    // position: absolute;
    // inset: 0;
  }
}
.dirty-fields-bubble {
  display: flex;
  width: 60px;
  height: 60px;
  background-color: rgba(0, 123, 170, 0.6);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  color: white;
}

.badge-custom {
  font-size: 1em;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

.badge-custom .btn {
  margin-left: 0.3rem;
  vertical-align: middle;
}

.img-file-id-input .clickable-area {
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;
}

.img-file-id-input .clickable-area:hover::after {
  content: "";

  background: rgba(255, 255, 255, 0.2); /* Light white overlay */
  border: 2px #1aa9a1; /* Bootstrap primary color for example */
}

.img-file-id-input .clickable-area:hover {
  box-shadow: 0 0 20px rgba(0, 123, 255, 0.5); /* Soft blue glow */
}

/*Start of class for signatures end brandlogos*/
.img-wrap-marketingprofile {
  position: relative;
  padding: 0;
}

.img-wrap-marketingprofile .img-wrap {
  width: 142px;
  max-height: 141px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 20px rgba(0, 123, 255, 0.5);
  }
}

.img-wrap-marketingprofile .wrap-badge {
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 1000;
  opacity: 0.85;
  transition: opacity 0.3s ease;
}

.img-wrap-signatmarketingprofileure:hover .wrap-badge {
  opacity: 1;
}

.img-wrap-marketingprofile .floating-label-badge {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.img-wrap-marketingprofile:hover .floating-label-badge {
  opacity: 1;
}

.img-wrap-marketingprofile .remove-button {
  top: -5px;
  right: -5px;
  position: absolute;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.img-wrap-marketingprofile:hover .remove-button {
  opacity: 1;
}

.text-wrap-marketingprofile {
  width: 500px;
  padding-left: 25px;
  padding-top: 25px;
  padding-right: 25px;
}

.text-wrap-marketingprofile .form-control:hover {
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}
/*End of class signatures*/

.img-box {
  flex: 1 1 20%; // Allows dynamic sizing with a base size
}

.detail-card {
  border: 1px solid #e1e1e1; /* Light grey border */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: box-shadow 0.3s ease; /* Smooth transition for hover */
}

.detail-card:hover {
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.detail-card .order-item:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.package-item,
.product-item {
  border-top: 1px solid #ccc; /* Adds a subtle line between items */
  padding-top: 8px; /* Spacing within items */
  margin-left: 10px;
}

.detail-header-row,
.detail-items-row {
  padding: 12px; /* Consistent padding */
}

.detail-card .campaign-item:hover {
  text-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.custom-checkbox .form-check-input {
  background-color: white;
  border-color: #ced4da; /* The default Bootstrap border color for inputs */
}

.custom-checkbox .form-check-input:checked {
  background-color: $success; /* The default Bootstrap checked color */
  border-color: $success;
}

/*This is the class that is used for eDM in VariantProofing component*/
.img-edm-wrap {
  text-align: center;
  position: relative;
  iframe {
    width: auto;
    display: block;
    width: 100%;
    max-height: 100%;
    transition: transform 0.2s;
  }
  .abs-buttons {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    flex-direction: column;
    display: flex;
    padding: 1em;
    gap: 0.5em;
    z-index: 10;
  }
}

/*This is the class that is used for DM in VariantProofing component*/
.zoom-image-wrap {
  background-image: linear-gradient(rgb(235, 235, 235) 2px, transparent 2px),
    linear-gradient(90deg, rgb(235, 235, 235) 2px, transparent 2px),
    linear-gradient(rgb(235, 235, 235) 1px, transparent 1px),
    linear-gradient(90deg, rgb(235, 235, 235) 1px, rgb(255, 255, 255) 1px);
  background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
  background-position: -4px -10px, 0px -4px, 0px 0px, 0px 0px;
  position: relative;
  height: 100vh;

  .abs-buttons {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    padding: 1em;
    gap: 0.5em;
    z-index: 10;
  }

  .carousel {
    .react-transform-wrapper {
      margin: auto;
      max-width: 100%;
    }

    .proof-img {
      width: auto;
      display: block;

      transition: transform 0.2s;
    }
  }

  .canvas {
    max-height: 100vh;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: move;
  }
  .placeholder {
    width: 0;
    padding-bottom: calc(80vh - 2em); // Adjust as needed
  }
  // Fullscreen
  .fullscreen {
    width: 100vw;
    height: 100vh;

    .proof-img {
      max-height: 75%;
      max-width: 75%;
    }

    iframe {
      height: 100%;
      width: 100%;
    }
  }
}

.custom-modal-button {
  cursor: pointer;
  &:hover {
    color: $secondary;
  }
}

.customer-grid-card:hover {
  background-color: rgba(227, 148, 0, 0.7); //#006891;
  transition: all 0.2s ease;
}

.sidebar {
  transition: width 2s ease;
  display: block;
  width: 0;
}

.documents-hover-border:hover {
  border-color: rgb(255, 85, 85);
  background-color: rgb(255, 85, 85);
  h6 {
    color: white;
  }
  span {
    color: white;
  }
}

@import "colors";
@import "dark";
@import "effects";
@import "utils";
@import "forms";
@import "Card";
@import "CustomerListItem";
@import "DetailPage";
@import "Header";
@import "ImgBox";
@import "Layout";
@import "ListPage";
@import "Loader";
@import "PhoenixLogo";
@import "SideMenu";
