:root {
  --header-inner-height: 56px;
  --header-height: calc(var(--header-inner-height) + 6px);
  --footer-height: 48px;
  --heading-height: 141px;
  --heading-height-sm: 109px;
  --side-menu-width: 256px;
  --collapsed-menu-width: 60px;
  --collapsed-list-width: 550px;
  --sidebar-width: 400px;
}
.layout {
  &.with-fixed-header {
    padding-top: var(--header-height);
  }
  &.no-side-menu {
    --side-menu-width: 0;
    --collapsed-menu-width: 0;
  }
  transition: padding-left 0.2s;
  padding-left: var(--collapsed-menu-width);
  transition: padding-left 0.3s;
  @include media-breakpoint-up(xl) {
    padding-left: var(--collapsed-menu-width);
    &.menuOpen {
      padding-left: var(--side-menu-width);
    }
  }
  @include media-breakpoint-down(sm) {
    padding-left: 0;
  }
}
.v-center {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.dashboard-wrap {
  padding: 2em;
  @include media-breakpoint-down(sm) {
    padding: 1em;
  }
}
.page-head {
  @extend .dashboard-wrap;
  &.fixed-height {
    min-height: var(--heading-height);
    h1 {
      font-size: 2.5rem;
    }
  }
  @include media-breakpoint-down(sm) {
    &.fixed-height {
      min-height: var(--heading-height-sm);
    }
    h1 {
      font-size: 30px;
    }
  }
}
.cancel-dashboard-wrap-side-bottom-padding-xs {
  @include media-breakpoint-down(sm) {
    margin-left: -2em;
    margin-right: -2em;
    margin-bottom: -2em;
  }
}
.cancel-round-border-xs {
  @include media-breakpoint-down(sm) {
    border-radius: 0;
  }
}
.container-margin {
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.fixed-height-card {
  min-height: calc(100vh - var(--header-height));
}
.full-page {
  height: calc(100vh - 56px);
}
.page-with-header {
  height: calc(100vh - 56px - 22em);
}
.error-modal-z-index {
  z-index: 1091;
}
.header-inner-height {
  height: var(--header-inner-height);
}

// Single scrollable section
.layout-flex {
  display: flex;
  height: 100vh;
}
.layout-main {
  width: 100%;
  height: 100%;
}

// only card-body scrollable
.card-main {
  height: 100%;
  width: 100%;
  > .card-body {
    overflow: auto;
    scrollbar-width: thin;
  }
}

// only scrollable section {
.section-main {
  overflow: auto;
}

// Slide out panel
.outlet-row {
  display: flex;
  overflow: hidden;
  height: 100%;
  .outlet-col {
    transition: width var(--transition-duration),
      flex-basis var(--transition-duration);
    background: rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
    // &.isActive {
    // }
    &.isCollapsed {
      flex-basis: var(--collapsed-list-width);
      width: var(--collapsed-list-width);
      &.isHidden {
        flex-basis: 0%;
        width: 0;
      }
    }
  }
  .sidebar-col {
    flex-basis: 0%;
    width: 0;
    &.isShown {
      flex-basis: var(--sidebar-width);
      width: var(--sidebar-width);
    }
  }
  .outlet-col + .outlet-col {
    flex-basis: 0%;
    flex-grow: 1;
    &.isActive {
      flex-basis: 100%;
    }
  }
  .visible-active {
    display: none;
  }
  .isActive.finished {
    .visible-active {
      display: block;
    }
  }
}
.scrollbar-thin {
  scrollbar-width: thin;
}

// Offcanvas
.offcanvas-backdrop.backdrop-filter {
  background: rgba(0, 0, 0, 0.5);
  &.show {
    opacity: 1;
    backdrop-filter: blur(5px);
  }
}
[data-bs-theme="dark"].offcanvas-end {
  border-left: none;
}
[data-bs-theme="dark"].offcanvas-start {
  border-right: none;
}
